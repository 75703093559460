<template>
  <ion-page id="ReservationPage">
    <app-background :class="{ 'is-green': currentReservation }" />

    <!-- app header -->
    <ion-header>
      <top-bar />
    </ion-header>

    <!-- app content -->
    <dev-box v-if="false">{{ store.state.currentDay.date }}</dev-box>
    <ion-content class="ReservationPage_container ion-padding">

      <!-- <ion-button @click="handleGoBackClick" size="large">{{ $t('ReservationPage.goBack') }}</ion-button> -->

      <div class="ReservationPage_content">

        <div
          class="ReservationPage_currentReservation"
          v-if="currentReservation"
        >
          <!-- top -->
          <section>
            <h3 class="ui-txt-xl ReservationPage_date">
              {{ moment(store.state.currentDay.date).locale(store.state.user.language).format('LL') }}
            </h3>
          </section>

          <!-- middle -->
          <section>
            <span class="ReservationPage_dataBox ui-txt-lg">
              <span class="ReservationPage_dataBoxLeft">
                {{ $t('ReservationPage.parkingSpace') }}:
              </span>
              <span class="ReservationPage_dataBoxRight">
                <span class="ReservationPage_dataBoxRight_stripe"></span>
                <span class="ReservationPage_dataBoxRight_content">
                  {{ currentReservation.parkingSpace.number }}
                </span>
              </span>
            </span>

            <span class="ReservationPage_dataBox ui-txt-lg">
              <span class="ReservationPage_dataBoxLeft">
                {{ $t('ReservationPage.floorNumber') }}:
              </span>
              <span class="ReservationPage_dataBoxRight">
                <span class="ReservationPage_dataBoxRight_stripe"></span>
                <span class="ReservationPage_dataBoxRight_content">
                  {{ currentReservation.parkingSpace.floor }}
                </span>
              </span>
            </span>
            

            <span
              v-if="isParkingMapEnabled"
              class="ReservationPage_mapButton ui-btn is-lg is-stretched"
              @click="handleParkingMapClick"
            >
              <ion-icon :src="mapOutline"></ion-icon>
              <span class="ui-btn_label">
                {{ $t('ReservationPage.parkingMap') }}
              </span>
            </span>
          </section>

          <!-- bottom -->
          <section>
            <span class="ReservationPage_cancelButton ui-btn is-lg is-stretched" @click="handleCancelClick(currentReservation.id)">
              <ion-icon :src="closeOutline"></ion-icon>
              <span
                :class="{
                  'ui-btn_label': true,
                  'is-disabled': store.state.isDeletingReservation,
                }"
              >
                {{ $t('ReservationPage.cancelReservation') }}
              </span>
            </span>
          </section>
        </div>

        <div class="ReservationPage_newReservation" v-else>
          <div class="ReservationPage_newReservationContainer">
            <h3 class="ui-txt-xl ReservationPage_date">
              {{ moment(store.state.currentDay.date).locale(store.state.user.language).format('LL') }}
            </h3>

            <!-- NEW RESERVATION -->
            <!-- <h1 class="ui-txt-xl">{{ $t('ReservationPage.addNewReservation') }}</h1> -->
            <!-- <ion-radio-group v-model="isCustomHoursSelected">
              <ion-item>
                <ion-label>{{ $t('ReservationPage.entireDay') }}</ion-label>
                <ion-radio slot="start" :value="false"></ion-radio>
              </ion-item>
              <ion-item disabled>
                <ion-label>{{ $t('ReservationPage.customHours') }}</ion-label>
                <ion-radio slot="start" :value="true"></ion-radio>
              </ion-item>
            </ion-radio-group> -->
            <template v-if="isCustomHoursSelected">
              <ion-item>
                <ion-label>{{ $t('ReservationPage.beginTime') }}</ion-label>
                <ion-datetime display-format="HH:mm" value="2012-12-15T12:34:20.789"></ion-datetime>
              </ion-item>
              <ion-item>
                <ion-label>{{ $t('ReservationPage.endTime') }}</ion-label>
                <ion-datetime display-format="HH:mm" value="2012-12-15T23:45:20.789"></ion-datetime>
              </ion-item>
            </template>
            <div class="ReservationPage_contentBottom">
              <span
                :class="{
                  'ReservationPage_confirmButton': true,
                  'ui-btn': true,
                  'is-lg': true,
                  'is-disabled': store.state.isPostingReservation,
                }"
                @click="handleConfirmClick"
              >
                <ion-icon :src="checkmarkOutline" />
                <span class="ui-btn_label">{{ $t('ReservationPage.confirmReservation') }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonItem,
  IonIcon,
  IonLabel,
  IonDatetime,
  IonHeader,
  modalController,
} from '@ionic/vue';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import router from '../router';
import actions from '@/store/actions';
import TopBar from '@/components/TopBar.vue';
import moment from 'moment';
import ParkingMapModal from '@/components/ParkingMapModal.vue';
import AppBackground from '@/components/AppBackground.vue';
import checkmarkOutline from 'ionicons/dist/svg/checkmark-outline.svg';
import mapOutline from 'ionicons/dist/svg/map-outline.svg';
import closeOutline from 'ionicons/dist/svg/close-outline.svg';
import DevBox from '@/components/DevBox.vue';
import EnvService from '@/services/env.service';
import store from "@/store/store";
const envService = new EnvService();

export default defineComponent({
  name: 'ReservationPage',
  components: {
    IonContent,
    IonPage,
    IonItem,
    IonIcon,
    IonLabel,
    IonDatetime,
    IonHeader,
    TopBar,
    AppBackground,
    DevBox,
  },
  // async mounted() {
  //   const maintenance = await actions.getMaintenanceMode()
  //   if(maintenance && !store.getters.isAdmin) {
  //     await router.push("/maintenance")
  //   }
  // },
  setup() {
    const store = useStore();
    actions.getMaintenanceMode().then(maintenance => {
      if(maintenance && !store.getters.isAdmin) {
        router.replace("/maintenance")
      }
    })

    const handleGoBackClick = () => {
      router.back();
    };

    const isCustomHoursSelected = ref(false);

    const handleConfirmClick = function () {
      const reservationDate = store.state.currentDay.date;
      const momentFrom = moment(reservationDate).startOf('day');
      actions.saveReservation(momentFrom);
    }

    const handleCancelClick = function (reservationId: any) {
      actions.deleteReservation(reservationId);
    }

    const handleParkingMapClick = async function () {
      const modal = await modalController
        .create({
          component: ParkingMapModal,
          cssClass: 'ParkingMapModal',
          componentProps: {
            title: 'Floor map',
          },
        })
      return modal.present();
    }

    const isMapButtonAvailable = computed(() => {
      const currentHostname = window.location.hostname;
      return !['parkit-katowice.ista.com'].includes(currentHostname);
    });
    const isParkingMapEnabled = computed(() => {
      return envService.getCurrentBuilding() !== 'katowice';
    });

    return {
      moment,
      store,
      handleGoBackClick,
      isCustomHoursSelected,
      handleConfirmClick,
      handleCancelClick,
      handleParkingMapClick,
      currentReservation: computed(() => store.state.currentReservation),
      checkmarkOutline,
      mapOutline,
      closeOutline,
      isMapButtonAvailable,
      isParkingMapEnabled
    }
  }
});
</script>

<style lang="scss">
$primaryColor: #0e2d63;
.ReservationPage_container {
  display: flex;
}

.ReservationPage_content {
  margin-left: -1vw;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.ReservationPage_date {
  margin-top: -0.5em;
  color: #777;
  position: relative;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.ParkingMapModal {
  --width: 100%;
  --height: 100%;
}

.ReservationPage_newReservation {
  transform: skewX(-14deg);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  padding: 5.2vw;
}

  .ReservationPage_newReservationContainer {
  transform: skewX(14deg);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ReservationPage_confirmButton {
  color: #fff;
  margin-top: 2.75vw;
  padding: 0 1.25em;
  ion-icon + .ui-btn_label {
    margin-left: 0.85em;
  }
}

.ReservationPage_currentReservation {
  height: 100%;
  color: #fff;
  max-width: calc(20vw + 300px);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.ReservationPage_mapButton {
  margin-bottom: 0.35em;
  ion-icon + .ui-btn_label {
    margin-left: 0.85em;
  }
}

.ReservationPage_mapButton,
.ReservationPage_cancelButton {
  justify-content: center;
}

.ReservationPage_cancelButton {
  margin-bottom: -0.25em;
}

.ReservationPage_dataBox {
  background: rgba(0, 0, 0, 0.4);
  display: block;
  font-weight: 500;
  border-radius: 999px;
  overflow: hidden;
  display: flex;
  backdrop-filter: blur(4px);
  align-items: stretch;
  margin-bottom: 0.35em;
}

.ReservationPage_dataBoxLeft {
  flex-grow: 1;
  padding-top: 0.34em;
  padding-bottom: 0.34em;
  padding-left: 1.5em;
}

.ReservationPage_dataBoxRight {
  display: flex;
}

.ReservationPage_dataBoxRight_stripe {
  position: relative;
  overflow: hidden;
  width: 1em;
  &:before {
    display: block;
    content: "";
    // background: rgba(2, 19, 49, 0.6);
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    transform: skewX(-14deg);
    right: -15px;
  }
}

.ReservationPage_dataBoxRight_content {
  // background: rgba(2, 19, 49, 0.6);
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  padding-right: 1.5em;
  padding-left: 0.8em;
}

</style>
