<template>
  <ion-page id="CallbackPage">
    <div class="CallbackPage_content">
      <div class="lds-heart"><div></div></div>
      <h1 class="CallbackPage_maintenance">Park.it is under maintenance<br>we'll come back soon...</h1>
      <div class="CallbackPage_loginPanel">
        <p>
          <span  class="CallbackPage_link" @click="handleHomeClick">
            Home Screen
          </span>
        </p>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import AuthService from '@/services/auth.service';
import router from '@/router/index';
import { store } from '@/store/store';
import actions from "@/store/actions";
const auth = new AuthService();
export default defineComponent({
  name: 'MaintenancePage',
  components: {
    IonPage,
  },
  setup() {
    setInterval(function () {
      actions.getMaintenanceMode().then((value) => {
        if(!value) {
          router.push("/home")
          location.reload();
        }
      })
    }, 60 * 1000);

    const handleSoftLogoutClick = () => {
      auth.logout();
    }
    const handleForceLogoutClick = () => {
      window.location.href = 'https://fs.ista.com/adfs/ls/?wa=wsignoutcleanup1.0';
    }
    const handleLoginClick = () => {
      auth.login();
    }
    const handleHomeClick = () => {
      router.push('/home');
    }

    return {
      handleSoftLogoutClick,
      handleForceLogoutClick,
      handleLoginClick,
      handleHomeClick
    }
  },
});
</script>

<style lang="scss">
#CallbackPage {
  width: 100vw;
  height: 100vh;
  display: block;
  background: #05418f;
  color: #fff;
}
.CallbackPage_loginPanel {
  line-height: 1.5em;
  border: solid 1px rgba(255, 255, 255, 0.2);
  padding: 10px 40px 20px 40px;
  margin-top: 20px;
  border-radius: 10px;
}
.CallbackPage_link {
  font-size: 1.25em;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  &:hover {
    border-bottom-color: #fff;
    color: #fff;
  }
}
.CallbackPage_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}
.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #fff;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.CallbackPage_maintenance {
  color: #fff;
  text-align: center;
}
</style>
