<template>
  <!--
    We can't add className to page until ionic-vue is fixed like in:
    https://github.com/ionic-team/ionic-framework/pull/22666
   -->
  <ion-page id="HomePage">
    <app-background />
    <!-- <img src="/photos/home-overlay.jpg" class="HomePage_reference"> -->
    <dev-box v-if="false">store.state: {{ store.state }}</dev-box>
    <!-- app header -->
    <ion-header>
      <top-bar />
    </ion-header>

    <!-- app content -->
    <ion-content class="HomePage_contentWrapper ion-padding">
      <div class="HomePage_content">
        <day-card
          v-for="(day, index) in currentWeek"
          :key="index"
          :day="day"
          button
          @click="handleDayCardClick(day)"
        />
      </div>
    </ion-content>

    <!-- app footer -->
    <ion-footer>
      <ion-toolbar class="HomePage_footerToolbar">
        <div class="HomePage_footerStart" slot="start">
          <span
            v-if="!isGuest"
            :class="{
              'HomePage_roundBtn': true,
              'ui-btn': true,
              'is-sm': true,
              'is-black': true,
            }"
            @click="handlePreviousWeekClick"
          >
            <ion-icon class="HomePage_arrowIcon" :src="chevronBackOutline"></ion-icon>
            <span class="ui-btn_label">{{ $t('HomePage.previous') }}</span>
          </span>
        </div>

        <ion-title class="HomePage_loadingReservations ui-txt-sm">
          <ion-spinner
            v-if="$store.state.isGettingReservations"
            name="lines"
          />
          {{ getBrowsedDateRange }}
        </ion-title>

        <div class="HomePage_footerEnd" slot="end">
          <span
            v-if="!isGuest"
            :class="{
              'HomePage_roundBtn': true,
              'ui-btn': true,
              'is-sm': true,
              'is-black': true,
              'is-disabled': isNextWeekDisabled,
            }"
            @click="handleNextWeekClick"
          >
            <span class="ui-btn_label">{{ $t('HomePage.next') }}</span>
            <ion-icon class="HomePage_arrowIcon" :src="chevronForwardOutline"></ion-icon>
          </span>

        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonFooter,
  IonTitle,
  IonIcon,
  IonSpinner,
} from '@ionic/vue';
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import DayCard from '@/components/DayCard.vue';
import router from '../router';
import TopBar from '@/components/TopBar.vue';
import actions from '@/store/actions';
import moment from 'moment';
import DevBox from '@/components/DevBox.vue';
import AppBackground from '@/components/AppBackground.vue';
import chevronForwardOutline from 'ionicons/dist/svg/chevron-forward-outline.svg';
import chevronBackOutline from 'ionicons/dist/svg/chevron-back-outline.svg';
import store from "@/store/store";

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonFooter,
    DayCard,
    TopBar,
    IonTitle,
    DevBox,
    AppBackground,
    IonIcon,
    IonSpinner,
  },
  setup() {
    const store = useStore();

    // animated scroll to
    const scrollTo = function (element: Element, to: number, duration: number) {
      if (duration <= 0) return;
      const difference = to - element.scrollTop;
      const perTick = difference / duration * 10;

      setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 3);
      }, 3);
    }

    // smoothly scrolls content to top
    const scrollContentToTop = function () {
      const contentWrapperEl = document.querySelector('.HomePage_contentWrapper');
      if (!contentWrapperEl) return;

      const contentWrapperShadowRoot = contentWrapperEl.shadowRoot;
      if (!contentWrapperShadowRoot) return;

      const contentScrollEl = contentWrapperShadowRoot.querySelector('.inner-scroll');
      if (!contentScrollEl) return;

      scrollTo(contentScrollEl, 0, 350);
    }

    // load previous week reservations
    const handlePreviousWeekClick = async () => {
      await actions.loadWeekReservations(-7);
      scrollContentToTop();
    };

    // load next week reservations
    const handleNextWeekClick = async () => {
      await actions.loadWeekReservations(7);
      scrollContentToTop();
    };

    // @TODO possibly dev code
    (window as any).handlePreviousWeekClick = handlePreviousWeekClick;
    (window as any).handleNextWeekClick = handleNextWeekClick;

    const handleDayCardClick = (day: any) => {
      store.commit('SET_CURRENT_DAY', day);
      // const firstReservation = day.reservations.find((d: any) => {
      //   // @TODO return username's reservations
      //   return true;
      // });
      const firstReservation = day.reservations[0];
      store.commit('SET_CURRENT_RESERVATION', firstReservation ? firstReservation : null);
      router.push('/reservation');
    };

    const getBrowsedDateRange = computed(() => {
      // return `${store.state.browsedWeekStart.format('DD MMM YYYY')} - ${store.state.browsedWeekEnd.format('DD MMM YYYY')}`;
      return moment(store.state.browsedWeekStart).locale(store.state.user.language).format('DD MMM YYYY');
    });

    const isNextWeekDisabled = computed(() => {
      const nextWeek = store.state.browsedWeekStart.clone().add(7, 'days');
      const twoWeeksLater = moment().clone().add(14, 'days');
      const moreThanTwoWeeksDaysLater = twoWeeksLater.clone().add(1, "days").startOf("day").hour(20);
      return !(nextWeek.isBefore(twoWeeksLater) || moment().add(15, "days").isSame(moreThanTwoWeeksDaysLater, "day") && moment().hour() >= 20);
    });

    return {
      store,
      currentWeek: computed(() => {
        return store.state.currentWeek
      }), // map state to reactive computed prop
      handlePreviousWeekClick,
      handleNextWeekClick,
      handleDayCardClick,
      getBrowsedDateRange,
      isNextWeekDisabled,
      isGuest: computed(() => store.getters.isGuest),
      chevronBackOutline,
      chevronForwardOutline,
    }
  }
});
</script>

<style lang="scss">
$primaryColor: #0e2d63;

.HomePage_reference {
  display: none;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 9999;
  opacity: 1;
  // opacity: 0.5;
  // opacity: 0.1;
  // opacity: 0;
  pointer-events: none;
}

.HomePage_contentWrapper {
  // ...
}

.HomePage_loadingReservations {
  ion-spinner {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 6px;
  }
}

.HomePage_content {
  max-width: 1280px;
  margin: auto;
  padding-top: 1.5vw;
  height: 100%;
  display: flex;
  flex-direction: column;

  .DayCard {
    flex-shrink: 0;
  }
}

#HomePage {
  ion-footer {
    background: rgba(2, 19, 49, 0.6);
  }
}

.HomePage_footerToolbar {
  --min-height: 50px;
  max-width: 1300px;
  margin: auto;
}

.HomePage_footerToolbar {
  --background: transparent;
  color: #fff;
  backdrop-filter: blur(10px);
}

.HomePage_footerStart,
.HomePage_footerEnd {
  z-index: 1;
}

.HomePage_roundBtn {
  &.is-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  @media screen and (max-width: 430px) {
    height: 45px;
    line-height: 45px;
    width: 45px;
    font-size: 15px !important;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4) !important;
    &:hover {
      background: rgba(0, 0, 0, 0.5) !important;  
    }
    .ui-btn_label {
      display: none;
    }
    .HomePage_arrowIcon {
      margin: 0;
    }
  }

}
.HomePage_arrowIcon {
  color: #c4ff00;
}
</style>
