<template>
  <ion-page id="AdminPage">
    <app-background />
    <dev-box v-if="false">
      <pre>
        bookings {{ bookings }}
      </pre>
    </dev-box>
    <!-- app header -->
    <ion-header>
      <top-bar />
    </ion-header>

    <!-- app content -->
    <ion-content>
      <div class="AdminPage_container">

        <ion-grid>
          <ion-row>
            <ion-col size="12" sizeLg="8">
              <h1 class="AdminPage_pageTitle ui-txt-xl">{{ $t('AdminPage.adminPanel') }}</h1>
              <ion-card class="ion-padding AdminPage_navSegments">
                <ion-segment
                  :value="selectedSegment"
                  @ionChange="handleSegmentChange($event)"
                >
                  <ion-segment-button class="AdminPage_segmentButton ui-txt-md" value="users">
                    <ion-label>{{ $t('AdminPage.users') }}</ion-label>
                  </ion-segment-button>
                  <ion-segment-button class="AdminPage_segmentButton ui-txt-md" value="teams">
                    <ion-label>{{ $t('AdminPage.teams') }}</ion-label>
                  </ion-segment-button>
                  <ion-segment-button class="AdminPage_segmentButton ui-txt-md" value="booking">
                    <ion-label>{{ $t('AdminPage.booking') }}</ion-label>
                  </ion-segment-button>
                  <ion-segment-button class="AdminPage_segmentButton ui-txt-md" value="log">
                    <ion-label>{{ $t('AdminPage.log') }}</ion-label>
                  </ion-segment-button>
                </ion-segment>
              </ion-card>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="12" sizeLg="8" class="AdminPage_contentCol">

              <!-- ***************** USERS PAGE ***************** -->
              <ion-card v-if="selectedSegment === 'users'">
                <ion-card-header>
                  <ion-card-title>{{ $t('AdminPage.users') }} <small v-if="users">({{ users.length }})</small></ion-card-title>
                </ion-card-header>
                <ion-card-content class="AdminPage_usersContent">

                  <ion-searchbar
                    v-model="userSearchPhrase"
                    color="dark"
                    mode="ios"
                    :placeholder="$t('AdminPage.search')"
                  ></ion-searchbar>

                  <div class="AdminPage_usersLoader" v-if="store.state.isLoadingUsers">
                    <ion-spinner class="AdminPage_usersLoader_spinner"></ion-spinner>
                  </div>

                  <!-- primevue users grid -->
                  <!-- vueUuid resolvees: https://github.com/ionic-team/ionic-framework/issues/20106#issuecomment-774001524 -->
                  <data-table
                    :value="users"
                    :paginator="true"
                    :rows="15"
                    data-key="vueUuid"
                    sortField="username"
                    :sortOrder="1"
                    removableSort
                  >

                    <!-- User Name -->
                    <column
                      field="username"
                      :header="$t('AdminPage.name')"
                      :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.name') }}</span>
                        {{slotProps.data.firstName}}
                        {{slotProps.data.lastName}}
                        {{slotProps.data.username}}
                      </template>
                    </column>

                    <!-- Use Roles -->
                    <column
                      field="roles"
                      :header="$t('AdminPage.roles')"
                      style="width: 25%"
                      :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.roles') }}</span>
                        <ion-select
                          @ionChange="handleUserRolesChange($event.detail.value, slotProps.data.username, slotProps.data.roles)"
                          multiple
                          :value="slotProps.data.roles"
                          :ok-text="$t('AdminPage.accept')"
                          :cancel-text="$t('AdminPage.cancel')"
                        >
                          <ion-select-option
                            v-for="(roleItem, index) in allAvailableRoles"
                            :value="roleItem.role"
                            :key="index"
                            :disabled="isRoleOptionDisabled(roleItem, slotProps.data.username, slotProps.data.roles)"
                          >
                            {{ roleItem.fullName }}
                          </ion-select-option>
                        </ion-select>
                      </template>
                    </column>

                    <!-- User Teams -->
                    <column
                      field="teams"
                      :header="$t('AdminPage.teams')"
                      :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.teams') }}</span>
                        <ion-select
                          :placeholder="$t('AdminPage.noTeam')"
                          @ionChange="handleUserTeamsChange($event.detail.value, slotProps.data.username)"
                          :value="slotProps.data.teams"
                          :ok-text="$t('AdminPage.accept')"
                          :cancel-text="$t('AdminPage.cancel')"
                          :disabled="!IsTeamSelectEnabled(slotProps.data.roles)"
                        >
                          <ion-select-option
                            v-for="(teamItem, index) in teamOptions"
                            :value="teamItem.team"
                            :key="index"
                          >
                            {{ teamItem.fullName }}
                          </ion-select-option>
                        </ion-select>
                      </template>
                    </column>

                    <!-- User Parking place -->
                    <column
                      field="parkPlace"
                      :header="$t('AdminPage.space')"
                      :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.space') }}</span>
                        <ion-select
                          @ionChange="handleUserPlaceChange($event.detail.value, slotProps.data)"
                          :value="slotProps.data.spaceNumber"
                          :ok-text="$t('AdminPage.accept')"
                          :cancel-text="$t('AdminPage.cancel')"
                          :placeholder="$t('AdminPage.pickPlace')"
                          :disabled="!IsParkingSpaceSelectEnabled(slotProps.data.roles)"
                        >
                          <ion-select-option
                            v-for="(spaceItem, index) in parkingSpaces"
                            :value="spaceItem.number"
                            :key="index"
                            :disabled="isParkingSpaceDisabled(spaceItem, slotProps.data, index)"
                          >
                          {{ spaceItem.number ? spaceItem.number : $t('AdminPage.noParkingPlace') }}
                          {{ spaceItem.ownership ? `(${spaceItem.ownership.ownerId})` : null }}
                          </ion-select-option>
                        </ion-select>

                      </template>
                    </column>

                    <!-- User Car reg. number -->
                    <column
                      field="registrationNumber"
                      :header="$t('AdminPage.carRegNumber')"
                      :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.carRegNumber') }}</span>
                        <ion-input
                          @ionFocus="lastFocusedValue = $event.detail.target.value"
                          @ionBlur="handleUserRegNumChange($event.detail.target.value, slotProps.data.username)"
                          @keyup.enter="$event => $event.target.blur()"
                          :value="slotProps.data.registrationNumber"
                        ></ion-input>
                      </template>
                    </column>

                    <!-- User Actions -->
                    <column
                      :header="$t('AdminPage.actions')"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.actions') }}</span>
                        <ion-button
                          size="small"
                          color="danger"
                          @click="handleDeleteUserClick(slotProps.data.username)"
                        >{{ $t('AdminPage.delete') }}</ion-button>
                      </template>
                    </column>
                  </data-table>

                </ion-card-content>
              </ion-card>

              <!-- ***************** TEAMS PAGE ***************** -->
              <ion-card v-if="selectedSegment === 'teams'">
                <ion-card-header>
                  <ion-card-title>{{ $t('AdminPage.teams') }} <small v-if="allAvailableTeams">({{ allAvailableTeams.length }})</small></ion-card-title>
                </ion-card-header>

                <ion-card-content class="AdminPage_teamsContent" style="color: #fff">

                  <ion-searchbar
                    v-model="teamSearchPhrase"
                    color="dark"
                    mode="ios"
                    :placeholder="$t('AdminPage.search')"
                  ></ion-searchbar>

                  <!-- teams -->
                  <ion-row>

                    <div class="AdminPage_teamsLoader" v-if="store.state.isLoadingTeams">
                      <ion-spinner class="AdminPage_teamsLoader_spinner"></ion-spinner>
                    </div>

                    <!-- primevue teams grid -->
                    <!-- @dev when paginated, scroll to last page after creating team -->
                    <data-table
                      :value="allAvailableTeams"
                      class="p-datatable-responsive-demo"
                      :paginator="true"
                      :rows="15"
                      data-key="vueUuid"
                      sortField="team"
                      :sortOrder="1"
                      removableSort
                    >

                      <!-- Team Name -->
                      <column
                        field="team"
                        :header="$t('AdminPage.name')"
                        :sortable="true"
                      >
                        <template #body="slotProps">
                          <span class="p-column-title">{{ $t('AdminPage.name') }}</span>
                          <!-- this should not be editable, it's unique team ID -->
                          <p>{{ slotProps.data.team }}</p>
                        </template>
                      </column>

                      <!-- Team Full Name -->
                      <column
                        field="fullName"
                        :header="$t('AdminPage.fullName')"
                        :sortable="true"
                      >
                        <template #body="slotProps">
                          <span class="p-column-title">{{ $t('AdminPage.fullName') }}</span>
                          <ion-input
                            @ionFocus="lastFocusedValue = $event.detail.target.value"
                            @ionBlur="handleTeamFullNameChange($event.detail.target.value, slotProps.data.team)"
                            @keyup.enter="$event => $event.target.blur()"
                            :value="slotProps.data.fullName"
                          ></ion-input>
                        </template>
                      </column>

                      <!-- Team Description -->
                      <column
                        field="description"
                        :header="$t('AdminPage.description')"
                        :sortable="true"
                      >
                        <template #body="slotProps">
                          <span class="p-column-title">{{ $t('AdminPage.description') }}</span>
                          <ion-input
                            @ionFocus="lastFocusedValue = $event.detail.target.value"
                            @ionBlur="handleTeamDescriptionChange($event.detail.target.value, slotProps.data.team)"
                            @keyup.enter="$event => $event.target.blur()"
                            :value="slotProps.data.description"
                          ></ion-input>
                        </template>
                      </column>

                      <!-- Team Spaces -->
                      <column
                        field="spaces"
                        :header="$t('AdminPage.spaces')"
                        :sortable="true"
                      >
                        <template #body="slotProps">
                          <span class="p-column-title">{{ $t('AdminPage.spaces') }}</span>
                          <ion-input
                            @ionFocus="lastFocusedValue = $event.detail.target.value"
                            @ionBlur="handleTeamSpacesChange($event.detail.target.value, slotProps.data.team)"
                            @keyup.enter="$event => $event.target.blur()"
                            :value="slotProps.data.count"
                          ></ion-input>
                        </template>
                      </column>

                      <!-- Team Actions -->
                      <column
                        :header="$t('AdminPage.actions')"
                      >
                        <template #body="slotProps">
                          <span class="p-column-title">{{ $t('AdminPage.actions') }}</span>
                          <ion-button
                            size="small"
                            color="danger"
                            @click="handleDeleteTeamClick(slotProps.data.team)"
                          >{{ $t('AdminPage.delete') }}</ion-button>
                        </template>
                      </column>
                    </data-table>
                  </ion-row>

                  <ion-card-title class="ion-padding-top">{{ $t('AdminPage.createTeam') }}</ion-card-title>
                  <ion-row>
                    <ion-col>
                      <ion-item>
                        <ion-input
                          v-model="createdTeamName"
                          :placeholder="$t('AdminPage.name')"
                        ></ion-input>
                      </ion-item>
                    </ion-col>
                    <ion-col>
                      <ion-item>
                        <ion-input
                          v-model="createdTeamFullName"
                          :placeholder="$t('AdminPage.fullName')"
                        ></ion-input>
                      </ion-item>
                    </ion-col>
                    <ion-col>
                      <ion-item>
                        <ion-input
                          v-model="createdTeamDescription"
                          :placeholder="$t('AdminPage.description')"
                        ></ion-input>
                      </ion-item>
                    </ion-col>
                    <ion-col size="2">
                      <ion-button
                        size="medium"
                        expand="block"
                        color="success"
                        :disabled="!isCreateTeamAvailable"
                        @click="handleCreateTeamClick"
                      >{{ $t('AdminPage.create') }}</ion-button>
                    </ion-col>
                  </ion-row>

                </ion-card-content>
              </ion-card>

              <!-- ***************** BOOKINGS PAGE ***************** -->
              <ion-card v-if="selectedSegment === 'booking'">
                <ion-card-header>
                  <ion-card-title>
                    {{ $t('AdminPage.booking') }}
                    <small v-if="bookings">(
                      {{ bookings.length }}
                      <small v-if="enrichedBookings"> of {{ enrichedBookings.length }}</small>
                      )</small>
                  </ion-card-title>
                </ion-card-header>
                <ion-card-content class="AdminPage_bookingContent">

                  <ion-grid>
                    <ion-row>
                      <ion-col class="ion-float-left">
                        <ion-searchbar
                            v-model="bookingSearchPhrase"
                            color="dark"
                            mode="ios"
                            :placeholder="$t('AdminPage.search')"
                          ></ion-searchbar>
                      </ion-col>
                      <ion-col class="ion-float-left">
                        <ion-datetime-button mode="ios" datetime="datetime" color="light" style="position: relative; top: 30px; right: -140px;"></ion-datetime-button>
                        <ion-modal :keep-contents-mounted="true">
                          <ion-datetime
                              :show-default-buttons="true"
                              :first-day-of-week="1"
                              id="datetime"
                              presentation="date"
                              @ionChange="onChangeBookingDate"
                          ></ion-datetime>
                        </ion-modal>
                      </ion-col>
                    </ion-row>
                  </ion-grid>


                  <div class="AdminPage_bookingsLoader" v-if="store.state.isLoadingBookings">
                    <ion-spinner class="AdminPage_bookingsLoader_spinner"></ion-spinner>
                  </div>

                  <!-- Bookings table -->
                  <!-- vueUuid resolvees: https://github.com/ionic-team/ionic-framework/issues/20106#issuecomment-774001524 -->
                  <data-table
                    :value="enrichedBookings"
                    :paginator="true"
                    :rows="25"
                    data-key="vueUuid"
                    sortField="number"
                    :sortOrder="1"
                    removableSort
                  >
                    <column
                      field="number"
                      :header="$t('AdminPage.space')"
                      style="width: 25%"
                      :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.space') }}</span>
                        {{ slotProps.data.number }}
                      </template>
                    </column>
                    <column
                      field="booking.bookingUserId"
                      :header="$t('AdminPage.name')"
                      :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.name') }}</span>
                        <template v-if="slotProps.data.booking">
                          {{ slotProps.data.booking.bookingUserId }}
                          <template v-if="slotProps.data.booking.bookingTeamId">
                            ({{ slotProps.data.booking.bookingTeamId }})
                          </template>
                        </template>
                      </template>
                    </column>
                    <!-- Booking Actions -->
                    <column
                        :header="$t('AdminPage.actions')"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.actions') }}</span>
                        <ion-button
                            size="small"
                            color="danger"
                            :disabled="slotProps.data.booking === undefined || slotProps.data.booking == null"
                            @click="handleDeleteReservationClick(slotProps.data.booking)"
                        >{{ $t('AdminPage.delete') }}</ion-button>
                      </template>
                    </column>
                  </data-table>

                </ion-card-content>
              </ion-card>

              <!-- ***************** LOG PAGE ***************** -->
              <ion-card v-if="selectedSegment === 'log'">
                <ion-card-header>
                  <ion-card-title>
                    {{ $t('AdminPage.log') }}
                    <small v-if="logs">(
                      {{ logs.length }}
                      <small v-if="enrichedLogs"> of {{ enrichedLogs.length }}</small>
                      )</small>
                  </ion-card-title>
                </ion-card-header>
                <ion-card-content class="AdminPage_bookingContent">

                  <ion-grid>
                    <ion-row>
                      <ion-col class="ion-float-left">
                        <ion-searchbar
                            v-model="logsSearchPhrase"
                            color="dark"
                            mode="ios"
                            :placeholder="$t('AdminPage.search')"
                        ></ion-searchbar>
                      </ion-col>
                      <ion-col class="ion-float-left">
                        <ion-datetime-button mode="ios" datetime="datetime" color="light" style="position: relative; top: 30px; right: -140px;"></ion-datetime-button>
                        <ion-modal :keep-contents-mounted="true">
                          <ion-datetime
                              :show-default-buttons="true"
                              :first-day-of-week="1"
                              id="datetime"
                              presentation="date"
                              @ionChange="onChangeLogDate"
                          ></ion-datetime>
                        </ion-modal>
                      </ion-col>
                    </ion-row>
                  </ion-grid>


                  <div class="AdminPage_logsLoader" v-if="store.state.isLoadingLogs">
                    <ion-spinner class="AdminPage_logsLoader_spinner"></ion-spinner>
                  </div>

                  <!-- Logs table -->
                  <!-- vueUuid resolvees: https://github.com/ionic-team/ionic-framework/issues/20106#issuecomment-774001524 -->
                  <data-table
                      :value="enrichedLogs"
                      :paginator="true"
                      :rows="25"
                      data-key="vueUuid"
                      sortField="number"
                      :sortOrder="1"
                      removableSort
                  >
                    <column
                        field="userId"
                        :header="$t('AdminPage.name')"
                        style="width: 25%"
                        :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.name') }}</span>
                        {{ slotProps.data.userId }}
                      </template>
                    </column>
                    <column
                        field="placeNumber"
                        :header="$t('AdminPage.space')"
                        :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.space') }}</span>
                        {{ slotProps.data.placeNumber }}
                      </template>
                    </column>
                    <column
                        field="team"
                        :header="$t('AdminPage.teams')"
                        :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.teams') }}</span>
                        {{ slotProps.data.team }}
                      </template>
                    </column>
                    <column
                        field="action"
                        :header="$t('AdminPage.action')"
                        :sortable="false"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.action') }}</span>
                        <i v-if="slotProps.data.action == 'RESERVE'"  style="color: green" class="pi pi-check"></i>
                        <i v-if="slotProps.data.action != 'RESERVE'" style="color: red" class="pi pi-times"></i>
                      </template>
                    </column>
                    <column
                        field="actionDate"
                        :header="$t('AdminPage.actionDate')"
                        :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">{{ $t('AdminPage.actionDate') }}</span>
                        {{ slotProps.data.actionDate }}
                      </template>
                    </column>
                  </data-table>

                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size="12" sizeLg="4" class="AdminPage_statsCol">
              <!-- ***************** USERS SIDEBAR ***************** -->
              <ion-card v-if="selectedSegment === 'users'">
                <ion-card-header>
                  <ion-card-title>{{ $t('AdminPage.parkingSpacesSummary') }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <div class="AdminPage_statsLoader" v-if="store.state.isLoadingSpacesSummary">
                    <ion-spinner class="AdminPage_statsLoader_spinner"></ion-spinner>
                  </div>
                  <ion-grid class="AdminPage_usersSummary">
                    <ion-row>
                      <ion-col size="4">
                        <b>{{ $t('AdminPage.totalPool') }}:</b>
                      </ion-col>
                      <ion-col size="8">
                        <p><strong class="ui-txt-lg">{{ spacesSummary.totalCount }}</strong></p>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col size="4">
                        <b>{{ $t('AdminPage.assignedToVipTl') }}:</b>
                      </ion-col>
                      <ion-col size="8">
                        <p><strong class="ui-txt-lg">{{ spacesSummary.withOwnerCount }}</strong></p>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col size="4">
                        <b>{{ $t('AdminPage.remainingPool') }}:</b>
                      </ion-col>
                      <ion-col size="8">
                        <p><strong class="ui-txt-lg">{{ spacesSummary.withoutOwnerCount }}</strong></p>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>

              <!-- ***************** TEAMS SIDEBAR ***************** -->
              <ion-card v-if="selectedSegment === 'teams'">
                <ion-card-header>
                  <ion-card-title>{{ $t('AdminPage.spacesSummary') }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <div class="AdminPage_statsLoader" v-if="store.state.isLoading">
                    <ion-spinner class="AdminPage_statsLoader_spinner"></ion-spinner>
                  </div>
                  <ion-grid class="AdminPage_spacesSummary">
                    <ion-row>
                      <ion-col size="4">
                        <b>{{ $t('AdminPage.remainingPool') }}:</b>
                      </ion-col>
                      <ion-col size="8">
                        <p><strong class="ui-txt-lg">{{ spacesSummary.totalTeamPlacesCount }}</strong></p>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col size="4">
                        <b>{{ $t('AdminPage.assignedSpaces') }}:</b>
                      </ion-col>
                      <ion-col size="8">
                        <p><strong class="ui-txt-lg">{{ spacesSummary.assignedToTeamsCount }}</strong></p>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col size="4">
                        <b>{{ $t('AdminPage.availableSpaces') }}:</b>
                      </ion-col>
                      <ion-col size="8">
                        <p><strong class="ui-txt-lg">{{ remainingTeamPlacesCount }}</strong></p>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>

            </ion-col>
          </ion-row>
        </ion-grid>

      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonSearchbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonSegmentButton,
  IonSegment,
  IonItem,
  IonSpinner,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  DatetimeCustomEvent
} from '@ionic/vue';
import { computed, defineComponent, ref } from 'vue';
import TopBar from '@/components/TopBar.vue';
import { useStore } from 'vuex';
import actions from '@/store/actions';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import _ from 'lodash';
import AppBackground from '@/components/AppBackground.vue';
import i18n from '@/plugins/i18n';
import eventsService from '@/services/events.service';
import moment from 'moment';
import { IBooking, ILog } from '@/services/authorization.mock';
import { parseISO, format } from 'date-fns';
import store from "@/store/store";
import router from "@/router";

export default defineComponent({
  name: 'AdminPage',
  components: {
    IonContent,
    IonPage,
    TopBar,
    IonHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonCard,
    IonSearchbar,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonSelect,
    IonSelectOption,
    DataTable,
    Column,
    IonLabel,
    IonSegmentButton,
    IonSegment,
    IonItem,
    IonSpinner,
    AppBackground,
    IonDatetime, IonDatetimeButton, IonModal
  },
  setup() {
    const store = useStore();
    const selectedSegment = ref('users');
    const createdTeamName = ref('');
    const createdTeamFullName = ref('');
    const createdTeamDescription = ref('');
    const lastFocusedValue = ref('');
    const userSearchPhrase = ref('');
    const teamSearchPhrase = ref('');
    const bookingSearchPhrase = ref('');
    const bookingSearchDate = ref('');
    const logsSearchPhrase = ref('');
    const logsSearchDate = ref('');

    // @TODO ionic ionChange triggers automatically on paginiton change
    // https://forum.ionicframework.com/t/ionchange-triggers-automatically/194210/15

    const handleUserRolesChange = (newRoles: string[], username: string) => {
      // auto-remove guest role on any role update
      let roles = newRoles;
      if (roles.length > 1) roles = roles.filter(r => r !== 'guest');

      actions.updateUser({
        username,
        roles,
      });
      // refresh token if user updated his own roles
      if (store.state.user.session.login.toLowerCase() === username.toLowerCase()) {
        // actions.initSession(); // causes userRolesChange trigger twice
      }
    }

    const handleUserTeamsChange = (newValue: string, username: string) => {
      // empty newValue clears user teams
      actions.updateUser({
        username,
        teams: newValue ? [newValue] : [], // avoids setting [""] as teams while clearing
      });
    }

    const handleUserPlaceChange = (newSpaceNumber: number, userData: any) => {
      let ownerId = userData.username;
      let ownerTeamId = userData.teams[0];

      if (newSpaceNumber) {
        const parkingSpaceNumber = newSpaceNumber;
        actions.setParkingOwner(ownerId, ownerTeamId, parkingSpaceNumber);
      } else {
        const parkingSpaceNumber = userData.spaceOwnership.parkingSpace.number;
        actions.deleteParkingSpaceOwnership(parkingSpaceNumber)
      }
    }

    const handleUserRegNumChange = (newValue: string, username: string) => {
      if (lastFocusedValue.value === newValue) return;
      actions.updateUser({
        username,
        registrationNumber: newValue,
      });
    }

    const handleDeleteUserClick = (username: string) => {
      actions.deleteUser(username);
    }

    const handleDeleteTeamClick = (team: string) => {
      actions.deleteTeam(team);
    }

    const handleDeleteReservationClick = (booking: any) => {
      if(booking !== undefined && booking != null) {
        actions.deleteAdminReservation(booking.id);
      }
    }

    const handleTeamFullNameChange = (newValue: string, team: string) => {
      if (lastFocusedValue.value === newValue) return;
      actions.updateTeam({
        team,
        fullName: newValue,
      });
    }

    const onChangeBookingDate = function(ev: DatetimeCustomEvent) {
      actions.loadBookings(moment(ev.detail.value))
    }

    const onChangeLogDate = function(ev: DatetimeCustomEvent) {
      actions.loadLogs(moment(ev.detail.value))
    }

    const handleTeamDescriptionChange = (newValue: string, team: string) => {
      if (lastFocusedValue.value === newValue) return;
      actions.updateTeam({
        team,
        description: newValue,
      });
    }

    const handleTeamSpacesChange = (newValue: string, team: string) => {
      if (lastFocusedValue.value === newValue) return;
      let count = parseInt(newValue, 10);

      if (isNaN(count)) {
        eventsService.emit('ADD_TOAST', {
          severity: 'warn',
          detail: (i18n as any).global.t('AdminPage.mustBeANumber'),
          life: 4000,
        });
        actions.loadTeams();
        return;
      }

      // @DEV disabled this limitation after new team decission
      // force at least 1 parking space for team while updating
      // if (!count) {
      //   count = 1;
      //   console.warn('Forcing new space to 1. Service requires team to have at least 1 parking space.');
      // }

      actions.updateTeamSpaces(
        count,
        team,
      );
    }

    const handleSegmentChange = (event: any) => {
      selectedSegment.value = event.detail.value;
    }

    // disable removing admin role for myself (since it cuts access to admin api)
    const isRoleOptionDisabled = function (
      option: Record<string, unknown>,
      optionUserName: string,
      userRoles: Array<string>,
    ) {
      // https://github.com/ionic-team/ionic-framework/issues/10178 no option click support

      const sessionUserName = store.state.user.session.profile.login;
      const isOptionForMyself = optionUserName.toLowerCase() === sessionUserName.toLowerCase();
      // @TODO review this outdated roles
      const isAdminRole = option.role === 'admin';
      const isGuestRole = option.role === 'guest';
      const isAlreadyEnabled = userRoles.includes('admin');
      return (isOptionForMyself && isAdminRole && isAlreadyEnabled) || isGuestRole;
    }

    const handleCreateTeamClick = async function () {
      const createTeamResponse = await actions.createTeam(
        createdTeamDescription.value,
        createdTeamFullName.value,
        createdTeamName.value,
      );
      // handle missing arguments case
      if (!createTeamResponse) return;

      // handle success response
      if (!createTeamResponse.isError) {
        createdTeamDescription.value = '';
        createdTeamFullName.value = '';
        createdTeamName.value = '';
      }
    }

    const isCreateTeamAvailable = computed(() => {
      return createdTeamName.value && createdTeamFullName.value && createdTeamDescription.value;
    });

    const parkingSpaces = computed(() => {
      if (!store.state.parkingSpaces) return;
      let enrichedParkingSpaces = _.cloneDeep(store.state.parkingSpaces);
      // add special space for deleting assigned place
      enrichedParkingSpaces.unshift({ noSpace: true });
      return enrichedParkingSpaces;
    });

    const teamOptions = computed(() => {
      if (!store.state.teams) return;
      let teamOptions = _.cloneDeep(store.state.teams);
      // add special position for clearing team
      teamOptions.unshift({
        fullName: (i18n as any).global.t('AdminPage.noTeam'),
      });
      return teamOptions;
    });

    const spacesSummary = computed(() => store.state.spacesSummary);

    const remainingTeamPlacesCount = computed(() => {
      const calcRemainingPlaces = spacesSummary.value.totalTeamPlacesCount - spacesSummary.value.assignedToTeamsCount;
      return isNaN(calcRemainingPlaces) ? null : calcRemainingPlaces;
    });

    const isParkingSpaceDisabled = function (spaceItem: any) {
      const spaceHasOwnership = !!spaceItem.ownership;
      return spaceHasOwnership;
    }

    const IsTeamSelectEnabled = function(userRoles: any[]) {
      const enabledRoles = ['TEAM_LEADER', 'TEAM_MEMBER'];
      const hasEnabledRole = userRoles.some(r => enabledRoles.includes(r));
      return hasEnabledRole;
    }

    const IsParkingSpaceSelectEnabled = function(userRoles: any[]) {
      const enabledRoles = ['TEAM_LEADER', 'VIP'];
      const hasEnabledRole = userRoles.some(r => enabledRoles.includes(r));
      return hasEnabledRole;
    }

    const users = computed(() => {
      const phrase = userSearchPhrase.value.trim().toLocaleLowerCase();
      if (phrase && store.state.users) {
        return store.state.users.filter((u: any) => {
          return JSON.stringify(u).toLowerCase().includes(phrase)
        });
      }
      return store.state.users
    });

    const bookings = computed(() =>  store.state.bookings );
    const logs = computed(() =>  store.state.logs );

    const enrichedBookings = computed(() => {
      const phrase = bookingSearchPhrase.value.trim().toLocaleLowerCase();
      let parkingSpaces = store.state.parkingSpaces;
      const bookings = store.state.bookings; // returns bookings for given date
      if (!parkingSpaces || !parkingSpaces.length) return [];

      // merge bookings with all parking spaces to show empty bookings as well
      let enrichedBookings = parkingSpaces.map( (p: any) => {
        const enrichedBooking = {
          spaceId: p.id,
          number: p.number,
          floor: p.floor,
          booking: null,
        }
        const booking = bookings.find((b: IBooking) => b.parkingSpace.id === p.id);
        if (booking) enrichedBooking.booking = booking;
        return enrichedBooking;
      });

      // filter out by search phrase
      if (phrase) {
        enrichedBookings = enrichedBookings.filter((e: any) => {
          return JSON.stringify(e).toLowerCase().includes(phrase)
        });
      }
      return enrichedBookings;
    });


    const enrichedLogs = computed(() => {
      const phrase = logsSearchPhrase.value.trim().toLocaleLowerCase();
      const logs_ = store.state.logs; // returns bookings for given date
      // merge bookings with all parking spaces to show empty bookings as well
      let enrichedLogs = logs_.map( (l: ILog) => {
        const enrichedLog = {
          userId: l.userId,
          placeNumber: l.placeNumber,
          reservationDate: l.reservationDate,
          team: l.team,
          action: l.action,
          actionDate: format(parseISO(l.actionDate), 'HH:mm:ss')
        }
        return enrichedLog;
      });

      // filter out by search phrase
      if (phrase) {
        enrichedLogs = enrichedLogs.filter((e: ILog) => {
          return JSON.stringify(e).toLowerCase().includes(phrase)
        });
      }
      return enrichedLogs;
    });


    const allAvailableTeams = computed(() => {
      const phrase = teamSearchPhrase.value.trim().toLocaleLowerCase();
      if (phrase && store.state.teams) {
        return store.state.teams.filter((u: any) => {
          return JSON.stringify(u).toLowerCase().includes(phrase)
        });
      }
      return store.state.teams
    });

    return {
      store,
      IsTeamSelectEnabled,
      IsParkingSpaceSelectEnabled,
      isParkingSpaceDisabled,
      handleUserRolesChange,
      users,
      bookings,
      logs,
      enrichedBookings,
      enrichedLogs,
      allAvailableRoles: computed(() => store.state.roles),
      allAvailableTeams,
      teamOptions,
      spacesSummary,
      remainingTeamPlacesCount,
      parkingSpaces,
      handleUserPlaceChange,
      handleDeleteUserClick,
      handleUserTeamsChange,
      handleUserRegNumChange,
      handleDeleteTeamClick,
      handleTeamDescriptionChange,
      handleTeamSpacesChange,
      handleSegmentChange,
      selectedSegment,
      isRoleOptionDisabled,
      createdTeamName,
      createdTeamFullName,
      createdTeamDescription,
      handleCreateTeamClick,
      isCreateTeamAvailable,
      handleTeamFullNameChange,
      lastFocusedValue,
      userSearchPhrase,
      teamSearchPhrase,
      bookingSearchPhrase,
      bookingSearchDate,
      logsSearchPhrase,
      logsSearchDate,
      onChangeBookingDate,
      onChangeLogDate,
      handleDeleteReservationClick
    }
  },

  async mounted() {
    const maintenance = await actions.getMaintenanceMode()
    if(maintenance && !store.getters.isAdmin) router.push("/maintenance")

    await actions.populateBookings();
    await actions.loadUsersAndSpaces();
    await actions.loadTeams();
    await actions.loadRoles();
  }
});
</script>

<style lang="scss">
$primaryColor: #0e2d63;

#AdminPage {
  ion-searchbar {
    max-width: 400px;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 5px;
  }
}

.AdminPage_content {
  // ...
}

.AdminPage_container {
  max-width: 1600px;
  margin: auto;
}

.AdminPage_contentCol {
  ion-card {
    background: #fff;
    padding: 0.75vw 0;
  }
  ion-card-title {
    color: #222;
  }
}

.AdminPage_statsCol {
  ion-card {
    backdrop-filter: blur(12px);
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 0.75vw 1.25vw;
  }
  ion-card-title {
    color: #fff;
  }
}

.picker-button{   color: green; }

.AdminPage_headerCol {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.AdminPage_filterCol {
  background: #f4f4f4;
  border-right: solid 2px white;
  &:last-child {
    border-right: none;
  }

}
.AdminPage_dataCol {
  ion-select,
  ion-input {
    border-bottom: solid 1px #ddd;
  }
}
.AdminPage_pageTitle {
  text-align: center;
  color: #fff;
  font-weight: 500;
}
.AdminPage_actionsCol {
  text-align: center;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 1000px) {
  .p-datatable {
    &.p-datatable-responsive-demo {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: .4rem;
          min-width: 30%;
          display: inline-block;
          margin: -.4em 1em -.4em -.4rem;
          font-weight: 500;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}
.AdminPage_usersSummary,
.AdminPage_spacesSummary {
  strong {
    color: #fff;
    // font-size: 1.75em;
  }
  ion-col {
    align-self: center;
    text-align: center;
  }
}

.AdminPage_navSegments {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  ion-segment-button {
    --padding-top: calc(5px + 0.6vw);
    --padding-bottom: calc(5px + 0.6vw);
    margin: -1px;
  }
  ion-label {
    font-weight: 500;
    color: #fff;
  }

  ion-segment {
    border: solid 1px rgb(140 163 200 / 54%);
  }

  .segment-button-checked {
    background: rgb(110 141 184 / 52%) !important;
  }
}
ion-datetime {
    --background: #c2c2c2;
    --background-rgb: 255,241,242;

    border-radius: 16px;
  box-shadow: transparent;
}
.AdminPage_usersContent,
.AdminPage_teamsContent {
  min-height: 300px;
}

.AdminPage_usersLoader,
.AdminPage_teamsLoader,
.AdminPage_bookingsLoader,
.AdminPage_statsLoader {
  display: flex;
  justify-content: center;
  // background: rgba(250, 250, 250, 0.5);
  backdrop-filter: blur(3px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.AdminPage_usersLoader,
.AdminPage_teamsLoader,
.AdminPage_bookingsLoader {
  padding-top: 150px;
}

.AdminPage_statsLoader {
  padding-top: 72px;
}

.AdminPage_usersLoader_spinner,
.AdminPage_teamsLoader_spinner,
.AdminPage_bookingsLoader_spinner {
  width: 90px;
  height: 90px;
}

.AdminPage_statsLoader_spinner {
  width: 55px;
  height: 55px;
  color: #fff;
}

.AdminPage_segmentButton {
  --indicator-color: transparent;
}
.AdminPage_dateTimePicker {
  width: 55px;
  height: 55px;
}

</style>
