import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "SideMenu_content" }
const _hoisted_2 = { class: "SideMenu_title" }
const _hoisted_3 = { class: "SideMenu_desktopOnly" }
const _hoisted_4 = { class: "SideMenu_desktopOnly" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    class: "SideMenu",
    side: "end",
    type: "overlay",
    "menu-id": "mainMenu",
    "content-id": "main"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_header, { class: "SideMenu_header" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('SideMenu.menuTitle')) + " " + _toDisplayString(_ctx.store.state.user.login), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, { class: "SideMenu_itemList" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item, {
                  class: "SideMenu_item",
                  button: "",
                  detail: false,
                  onClick: _ctx.handleHomeClick
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.homeOutline,
                      slot: "start"
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('SideMenu.home')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                (_ctx.isAdminEnabled)
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 0,
                      class: "SideMenu_item is-admin",
                      button: "",
                      detail: false,
                      onClick: _ctx.handleAdminClick
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: _ctx.newspaperOutline,
                          slot: "start"
                        }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('SideMenu.admin')) + " ", 1),
                            _createElementVNode("span", _hoisted_3, "(" + _toDisplayString(_ctx.$t('SideMenu.desktopOnly')) + ")", 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.isAdminEnabled)
                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('SideMenu.mode')) + " ", 1),
                            _createElementVNode("span", _hoisted_4, "(" + _toDisplayString(_ctx.$t('SideMenu.desktopOnly')) + ")", 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_toggle, {
                          modelValue: _ctx.isMaintenance,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isMaintenance) = $event)),
                          onClick: _ctx.handleSwitchMode,
                          onToggle: _ctx.handleSwitchMode,
                          "on-ion-change": _ctx.handleSwitchMode
                        }, null, 8, ["modelValue", "onClick", "onToggle", "on-ion-change"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_item, {
                  class: "SideMenu_item",
                  button: "",
                  detail: false,
                  onClick: _ctx.handleLogoutClick
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.logOutOutline,
                      slot: "start"
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('SideMenu.logout')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}