import LocalStorageService from '@/services/localStorage.service';
/**
 * We're using plain functions as replacement of vuex actions.
 * It supports typescript better since we're not calling methods by string.
 */

import { store } from '@/store/store';
import parkingReservationService from '@/services/parkingReservation.service';
import userAuthorizationService, { authorizationAxios } from '../services/userAuthorization.service';
import aggregatorService, { aggregatorAxios } from './../services/aggregator.service';
import { reservationAxios } from '../services/parkingReservation.service';
import moment from 'moment';
import { ICustomError } from '@/services/errorHandler';
import eventService from './../services/events.service';
import router from '@/router/index';
import { extend } from 'lodash';
import i18n from '@/plugins/i18n';
import _ from 'lodash';
import AuthService from '@/services/auth.service';
import { User } from 'oidc-client-ts';
import {AxiosResponse} from "axios";
// import helpers from '@/common/helpers';
const auth = new AuthService();

/**
 * Export defined actions
 */
const actions = {
  setLanguage: function (newLanguage: string): void {
    const language = newLanguage === 'gb' ? 'en' : newLanguage;
    store.commit('SET_USER_LANGUAGE', language);
    LocalStorageService.setLanguage(language);
    ((i18n as any).global.locale as string) = language;
    moment.locale(language);
  },

  // Tries to get User Data and seeds it or logs out
  initSession: async function (): Promise<void> {
    const userResponse: User | null = await auth.getUser();
    if (userResponse?.access_token && !userResponse.expired) {

      // set axios auth headers
      authorizationAxios.defaults.headers.common.Authorization = 'Bearer ' + userResponse.access_token;
      reservationAxios.defaults.headers.common.Authorization = 'Bearer ' + userResponse.access_token;
      aggregatorAxios.defaults.headers.common.Authorization = 'Bearer ' + userResponse.access_token;

      // get user profile (with roles!) from authService as well
      const userSessionResponse: any = await userAuthorizationService.getUserSession();
      if (userSessionResponse.isError) return;
      store.commit('SET_USER_SESSION_PARKIT', userSessionResponse)

      // set user session in store
      store.commit('SET_USER_SESSION', userResponse)

      // wait for eventService to start working and show notifications
      window.requestAnimationFrame(() => {
        eventService.emit('ADD_TOAST', {
          severity: 'success',
          summary: (i18n as any).global.t('Global.loggedInSummary'),
          detail: (i18n as any).global.t('Global.loggedInDetail', { username: userResponse.profile.email }),
          life: 5000,
        });

        const isGuest = store.getters.isGuest;
        if (isGuest) {
          eventService.emit('ADD_TOAST', {
            severity: 'info',
            summary: (i18n as any).global.t('Global.guestLoginSummary'),
            detail: (i18n as any).global.t('Global.guestLoginDetail'),
            life: 12000,
          });
        }
      });

    } else {
      if (router.currentRoute.value.path !== '/') actions.logout();
    }
  },

  /**
   * User Logout action
   */
  logout: async function(): Promise<void> {
    // clear session from local storage
    LocalStorageService.setUserData(null);
    await auth.logout();
  },

  /**
   * gets start of week moment, sets current week in storage
   * @param startOfTheWeek 
   */
  loadWeek: async function (startOfTheWeek: moment.Moment): Promise<void> {
    const loadWeekResponse = await parkingReservationService.loadWeek(
      startOfTheWeek,
    );
    if((loadWeekResponse as ICustomError).isError){
      return
    }
    store.commit('SET_CURRENT_WEEK', loadWeekResponse);
  },

  /**
   * triggers loadWeek for current moment
   */
  populateCurrentWeek: async function(): Promise<void> {
    const now = moment();
    await this.loadWeek(now.startOf('isoWeek'));
  },

  /**
   * triggers loadWeek for previous week moment
   */
  previousWeek: async function(): Promise<void> {
    const firstWeekDay = store.state.currentWeek[0].date;
    this.loadWeek(firstWeekDay.subtract(7, 'days'));
  },

  /**
   * triggers loadWeek for next week moment
   */
  nextWeek: async function (): Promise<void> {
    const firstWeekDay = store.state.currentWeek[0].date;
    this.loadWeek(firstWeekDay.add(7, 'days'));
  },

  /**
   * new version of loadWeek - loads reservation data into storage
   */
  loadReservations: async function (
    momentFrom: moment.Moment,
    momentTo: moment.Moment
  ): Promise<void> {
    const isGuest = store.getters.isGuest;
    if (isGuest) {
      console.warn('skipped loading reservatoin list, user is guest.');
      return
    }
    store.commit('SET_IS_GETTING_RESERVATIONS', true);
    let reservationsResponse = await parkingReservationService.getReservations(
      momentFrom.format('YYYY-MM-DD'),
      momentTo.format('YYYY-MM-DD'),
      false,
    )
    if (reservationsResponse.isError) {
      store.commit('SET_IS_GETTING_RESERVATIONS', false);

      if (reservationsResponse.message.includes('roles is required')) {
        reservationsResponse = []
      } else {
        eventService.emit('ADD_TOAST', {
          severity: 'info',
          detail: `${(i18n as any).global.t('Global.cantLoadReservations')}. ${reservationsResponse.message}`,
          life: 7000,
        });
        return
      }
    }

    if (!reservationsResponse) {
      console.warn('Reservations loading error or received no data.');
      return
    }

    let freeParkingSpacesResponse = await parkingReservationService.getFreeParkingSpaces(
      momentFrom.format('YYYY-MM-DD'),
      momentTo.format('YYYY-MM-DD'),
      false,
    )
    store.commit('SET_IS_GETTING_RESERVATIONS', false);

    if (!freeParkingSpacesResponse) {
      console.warn('could not load free parking spaces - retreived no parking spaces data.');
      return
    }

    // custom error handlers for getting reservation list
    if (freeParkingSpacesResponse.isError) {
      // mute error on no space case
      if (!freeParkingSpacesResponse.message.includes('space count not enough')) {
        eventService.emit('ADD_TOAST', {
          severity: 'info',
          detail: `${(i18n as any).global.t('Global.cantLoadFreeParkingSpaces')}. ${freeParkingSpacesResponse.message}`,
          life: 7000,
        });
      }
      // ITIS-453 replace error with empty collection
      freeParkingSpacesResponse = [];
    }

    const currentWeek: any[] = [];

    // populate current week with days
    for (const weekDay = moment(momentFrom); weekDay.isBefore(momentTo); weekDay.add(1, 'days')) {
      const newWeekDay = {
        date: weekDay.clone().startOf('day'),
        freeParkingSpacesCount: 0,
        reservations: [],
      };

      // enrich day with freeParkingSpacesCount (if server returned any currentDaySpaces)
      const currentDaySpaces = freeParkingSpacesResponse.find((p: any) => p.freeAt === newWeekDay.date.format('YYYY-MM-DD'));
      if (currentDaySpaces) {
        freeParkingSpacesResponse
        ? newWeekDay.freeParkingSpacesCount = currentDaySpaces.freeParkingSpacesCount
        : console.warn(`can't get freeParkingSpacesCount for ${newWeekDay.date.format('YYYY-MM-DD')}`);
      }

      // enrich day with (my) reservations collection
      const currentDayReservations = reservationsResponse.filter((reservation: any) => {
        const currentDayStart = weekDay.startOf('day').format('YYYY-MM-DD');
        const isReservationStartingThisDay = reservation.reservationDate === currentDayStart;
        return isReservationStartingThisDay;
      });

      newWeekDay.reservations = currentDayReservations;
      currentWeek.push(newWeekDay);
    }
    store.commit('SET_CURRENT_WEEK', currentWeek);
  },

  populateBookings: async function(): Promise<void> {
    const now = moment();
    await this.loadBookings(now);
  },

  loadBookings: async function (
    moment: moment.Moment
  ): Promise<void> {
    store.commit('SET_BOOKING_DATE', moment);
    const isGuest = store.getters.isAdmin;
    if (!isGuest) {
      return
    }
    store.commit('SET_IS_GETTING_BOOKINGS', true);
    let reservationsResponse = await parkingReservationService.getAdminReservations(
      moment.format('YYYY-MM-DD'),
      moment.format('YYYY-MM-DD'),
      false,
    )
    store.commit('SET_IS_GETTING_BOOKINGS', false);
    if (reservationsResponse.isError) {
      if (reservationsResponse.message.includes('roles is required')) {
        reservationsResponse = []
      } else {
        eventService.emit('ADD_TOAST', {
          severity: 'info',
          detail: `${(i18n as any).global.t('Global.cantLoadReservations')}. ${reservationsResponse.message}`,
          life: 7000,
        });
      }
      return
    }

    if (!reservationsResponse) {
      console.warn('Reservations loading error or received no data.');
      return
    }

    reservationsResponse.map((u: any) => {
      const decimalOffset = 1000000;
      u.vueUuid = Math.floor(Math.random() * decimalOffset);
    })

    store.commit('SET_BOOKINGS', reservationsResponse);
  },

  populateLogs: async function(): Promise<void> {
    const now = moment();
    await this.loadLogs(now);
  },

  loadLogs: async function (
      moment: moment.Moment
  ): Promise<void> {
    store.commit('SET_LOGS_DATE', moment);
    const isGuest = store.getters.isAdmin;
    if (!isGuest) {
      return
    }
    store.commit('SET_IS_GETTING_LOGS', true);
    let logsResponse = await parkingReservationService.getAdminLogs(
        moment.format('YYYY-MM-DD'),
        false,
    )
    store.commit('SET_IS_GETTING_LOGS', false);
    if (logsResponse.isError) {
      if (logsResponse.message.includes('roles is required')) {
        logsResponse = []
      } else {
        eventService.emit('ADD_TOAST', {
          severity: 'info',
          detail: `${(i18n as any).global.t('Global.cantLoadLogs')}. ${logsResponse.message}`,
          life: 7000,
        });
      }
      return
    }

    if (!logsResponse) {
      console.warn('Logs loading error or received no data.');
      return
    }

    logsResponse.map((u: any) => {
      const decimalOffset = 1000000;
      u.vueUuid = Math.floor(Math.random() * decimalOffset);
    })

    store.commit('SET_LOGS', logsResponse);
  },

  loadWeekReservations: async function (daysOffset = 0): Promise<void> {
    const weekStart = store.state.browsedWeekStart.clone().add(daysOffset, 'days');
    const weekEnd = weekStart.clone().endOf('isoWeek');
    store.commit('SET_BROWSED_WEEK_START', weekStart);
    store.commit('SET_BROWSED_WEEK_END', weekEnd);
    await this.loadReservations(weekStart, weekEnd);
  },

  /**
   * loads user list into storage
   */
  loadUsersAndSpaces: async function (): Promise<void> {
    store.commit('SET_IS_LOADING_USERS', true);
    const usersResponse = await userAuthorizationService.getUsers();
    if (usersResponse.isError || !usersResponse) {
      console.warn('User list loading error or received no data.');
      store.commit('SET_IS_LOADING_USERS', false);
      return;
    }

    const parkingSpaceOwnersResponse = await parkingReservationService.getParkingSpaceOwnerships();
    if (parkingSpaceOwnersResponse.isError || !parkingSpaceOwnersResponse) {
      console.warn('Parking owners loading error or received no data.');
      store.commit('SET_IS_LOADING_USERS', false);
      return;
    }

    const parkingSpacesResponse = await parkingReservationService.getParkingSpaces();
    if (parkingSpacesResponse.isError || !parkingSpacesResponse) {
      console.warn('Parking spaces loading error or received no data.');
      store.commit('SET_IS_LOADING_USERS', false);
      return;
    }

    // enrich parking spaces with owners
    parkingSpacesResponse.map((p: any) => {
      const assignedOwner = parkingSpaceOwnersResponse.find((o: any) =>  p.id === o.parkingSpace.id);
      if (assignedOwner) {
        const extractedOwner = _.cloneDeep(assignedOwner);
        delete extractedOwner.parkingSpace;
        p.ownership = extractedOwner
      }
    });
    store.commit('SET_PARKING_SPACES', parkingSpacesResponse);

    // enrich users with parking spaces and add random uuid to force-redraw
    usersResponse.map((u: any) => {
      const decimalOffset = 1000000;
      u.vueUuid = Math.floor(Math.random() * decimalOffset);
      const ownership = parkingSpaceOwnersResponse.find((p: any) => p.ownerId === u.username);
      if (ownership) {
        u.spaceOwnership = ownership;
        u.spaceNumber = ownership.parkingSpace.number;
      }
    });
    store.commit('SET_USERS', usersResponse);
    store.commit('SET_IS_LOADING_USERS', false);

    this.loadSpacesSummary();
  },

  setParkingOwner: async function (ownerId: string | null, ownerTeamId: string | null, spaceNumber: number): Promise<void> {
    store.commit('SET_IS_LOADING_USERS', true);
    const setParkingSpaceResponse = await parkingReservationService.setParkingSpaceOwnerships(ownerId, ownerTeamId, spaceNumber);
    if (!setParkingSpaceResponse.isError) {
      eventService.emit('ADD_TOAST', {
        severity: 'success',
        detail: `${(i18n as any).global.t('Global.parkingOwnerSet')} ${ownerId} -> ${spaceNumber}`,
        life: 4000,
      });
    }

    // this.loadParkingOwners();
    store.commit('SET_IS_LOADING_USERS', false);
    this.loadUsersAndSpaces();
  },

  deleteParkingSpaceOwnership: async function (parkingSpaceNumber: string): Promise<void> {
    store.commit('SET_IS_LOADING_USERS', true);
    const deleteParkingSpaceOwnershipResponse = await parkingReservationService.deleteParkingSpaceOwnership(parkingSpaceNumber);
    if (deleteParkingSpaceOwnershipResponse.isError) {
      store.commit('SET_IS_LOADING_USERS', false);
      return;
    }
    eventService.emit('ADD_TOAST', {
      severity: 'success',
      detail: `${(i18n as any).global.t('Global.parkingSpaceCleared')}`,
      life: 4000,
    });
    store.commit('SET_IS_LOADING_USERS', false);
    this.loadUsersAndSpaces();
  },

  /**
   * loads team list into storage
   */
  loadTeams: async function (): Promise<void> {
    store.commit('SET_IS_LOADING_TEAMS', true);
    const teamsResponse = await userAuthorizationService.getTeams();

    if (!teamsResponse) {
      console.warn('loadTeams received empty getTeams response, escaping action.');
      store.commit('SET_IS_LOADING_TEAMS', false);
      return;
    }

    if (teamsResponse.isError) {
      store.commit('SET_IS_LOADING_TEAMS', false);
      return;
    }

    // enrich data with team parking space
    let isParkingSpaceLoaded = true;
    const teamParkingSpaceResponse = await parkingReservationService.getTeamParkingSpace()

    if (teamParkingSpaceResponse.isError || !teamParkingSpaceResponse) {
      console.warn('Team parking space loading error or received no data.');
      isParkingSpaceLoaded = false;
    }

    teamsResponse.map((t: any) => {
      // enrich team with random uuid's to force table re-draw
      const decimalOffset = 1000000;
      t.vueUuid = Math.floor(Math.random() * decimalOffset);

      // enrich team with parking space count
      if (isParkingSpaceLoaded) {
        const parkingSpace = teamParkingSpaceResponse.find((p: any) => p.teamId === t.team);
        parkingSpace
          ? t.count = parkingSpace.count
          : console.warn(`could not get parking space count for ${t.team}`);
      }
    });

    store.commit('SET_TEAMS', teamsResponse);
    store.commit('SET_IS_LOADING_TEAMS', false);
    this.loadSpacesSummary();
  },

  /**
   * loads aggregated teams into storage
   */
  loadAggregatedTeams: async function (): Promise<void> {
    const aggregatedTeamsResponse = await aggregatorService.getTeams();
    if (aggregatedTeamsResponse.isError) return;
  },

  /**
   * loads role list into storage
   */
  loadRoles: async function (): Promise<void> {
    const rolesResponse = await userAuthorizationService.getRoles();
    if (rolesResponse.isError) return;

    store.commit('SET_ROLES', rolesResponse);
  },

  /**
   * loads parking spaces summary
   */
  loadSpacesSummary: async function (): Promise<void> {
    store.commit('SET_IS_LOADING_SPACES_SUMMARY', true);
    const userSpacesResponse = await parkingReservationService.getUserParkingSpacesSummary();
    const teamSpacesResponse = await parkingReservationService.getTeamParkingSpacesSummary();
    store.commit('SET_IS_LOADING_SPACES_SUMMARY', false);
    if (userSpacesResponse.isError || teamSpacesResponse.isError) return;
    const spacesSummary = {
      ...userSpacesResponse,
      ...teamSpacesResponse,
    }
    store.commit('SET_SPACES_SUMMARY', spacesSummary);
  },

  /**
   * replaces user data with new object
   * @param newUserData 
   */
  updateUser: async function (newUserData: Record<string, any>): Promise<void> {
    if (!store.state.users) return;
    const userToUpdate = store.state.users.find(u => u.username === newUserData.username);
    const newUser = extend({}, userToUpdate, newUserData);

    // remove vue re-draw triggering key
    delete newUser.vueUuid;

    // remove parking ownership data enriched from other service
    delete newUser.spaceNumber;
    delete newUser.spaceOwnership;
    
    store.commit('SET_IS_LOADING_USERS', true);
    const updateUserResponse = await userAuthorizationService.updateUser(newUser);

    if (updateUserResponse.isError && updateUserResponse.status === 409) {
      eventService.emit('ADD_TOAST', {
        severity: 'warn',
        detail: (i18n as any).global.t('AdminPage.roleSelectionDiscarded'),
        life: 7000,
      });
    }

    if (!updateUserResponse.isError) {
      eventService.emit('ADD_TOAST', {
        severity: 'success',
        detail: `${(i18n as any).global.t('Global.userChanged')} - ${newUser.username}`,
        life: 4000,
      });
    }
    store.commit('SET_IS_LOADING_USERS', false);
    this.loadUsersAndSpaces();
  },

  /**
   * removes user from databse
   * @param username 
   */
  deleteUser: async function (username: string): Promise<void> {
    store.commit('SET_IS_LOADING_USERS', true);
    const deleteUserResponse = await userAuthorizationService.deleteUser(username);
    if (!deleteUserResponse.isError) {
      eventService.emit('ADD_TOAST', {
        severity: 'warn',
        detail: `${(i18n as any).global.t('Global.userDeleted')} - ${username}`,
        life: 4000,
      });
    }
    store.commit('SET_IS_LOADING_USERS', false);
    this.loadUsersAndSpaces();
  },

  /**
   * removes team from database
   * @param team 
   */
  deleteTeam: async function (team: string): Promise<void> {
    store.commit('SET_IS_LOADING_TEAMS', true);
    const deleteTeamResponse = await userAuthorizationService.deleteTeam(team);
    if (!deleteTeamResponse.isError) {
      eventService.emit('ADD_TOAST', {
        severity: 'success',
        detail: `${(i18n as any).global.t('Global.teamDeleted')} - ${team}`,
        life: 4000,
      });
    }
    store.commit('SET_IS_LOADING_TEAMS', false);
    this.loadTeams();
  },

  /**
   * replaces team data with new object
   */
  updateTeam: async function (newTeamData: Record<string, any>): Promise<void> {
    if (!store.state.teams) {
      console.warn('store is missing teams to update');
      return
    }

    // prepare update payload based on previous team data
    const teamToUpdate = store.state.teams.find(t => t.team === newTeamData.team);
    const newTeam = extend({}, teamToUpdate, newTeamData);

    // remove vue re-draw triggering key
    delete newTeam.vueUuid;

    store.commit('SET_IS_LOADING_TEAMS', true);
    const updateTeamResponse = await userAuthorizationService.updateTeam(newTeam);

    if (!updateTeamResponse.isError) {
      store.commit('SET_IS_LOADING_TEAMS', false);
      eventService.emit('ADD_TOAST', {
        severity: 'success',
        detail: `${(i18n as any).global.t('Global.teamChanged')} - ${newTeam.team}`,
        life: 4000,
      });
    }

    store.commit('SET_IS_LOADING_TEAMS', false);
    this.loadTeams();
  },

  /**
   * Updates parking space count in team
   */
  updateTeamSpaces: async function (count: number, teamId: string): Promise<void> {
    store.commit('SET_IS_LOADING_TEAMS', true);
    const updateTeamParkingSpaceResponse = await parkingReservationService.updateTeamParkingSpace(count, teamId);
    
    if (updateTeamParkingSpaceResponse.isError && updateTeamParkingSpaceResponse.status === 400) {
      eventService.emit('ADD_TOAST', {
        severity: 'warn',
        detail: (i18n as any).global.t('Global.spaceCountUpdateError'),
        life: 4000,
      });
    }
    
    if (!updateTeamParkingSpaceResponse.isError) {
      eventService.emit('ADD_TOAST', {
        severity: 'success',
        detail: (i18n as any).global.t('Global.updatedSpaceCount'),
        life: 4000,
      });
    }

    store.commit('SET_IS_LOADING_TEAMS', false);
    this.loadTeams();
  },

  createTeam: async function (
      description: string,
      fullName: string,
      team: string
    ): Promise<any | void> {
    // @TODO disable creation for non-admin role
    if (!description || !fullName || !team) {
      eventService.emit('ADD_TOAST', {
        severity: 'error',
        detail: (i18n as any).global.t('Global.createTeamMissingData'),
        life: 4000,
      });
      return;
    }
    store.commit('SET_IS_LOADING_TEAMS', true);
    const createTeamResponse = await userAuthorizationService.createTeam(description, fullName, team);
    if (createTeamResponse.isError) {
      eventService.emit('ADD_TOAST', {
        severity: 'warn',
        detail: `${(i18n as any).global.t('Global.invalidTeamData')}`,
        life: 4000,
      });
    }
    if (!createTeamResponse.isError) {
      eventService.emit('ADD_TOAST', {
        severity: 'success',
        detail: `${(i18n as any).global.t('Global.teamCreated')} - ${fullName}`,
        life: 4000,
      });
    }
    store.commit('SET_IS_LOADING_TEAMS', false);
    this.loadTeams();
    return createTeamResponse;
  },

  /**
   * saves reservation
   */
  saveReservation: async function (
    from: moment.Moment,
  ): Promise<any> {
    store.commit('SET_IS_POSTING_RESERVATION', true);
    const saveReservationResponse = await parkingReservationService.saveReservation(from.format('YYYY-MM-DD'));
    store.commit('SET_IS_POSTING_RESERVATION', false);
    if (saveReservationResponse.isError) return;

    // reservation success
    eventService.emit('ADD_TOAST', {
      severity: 'success',
      summary: `${(i18n as any).global.t('Global.reservationCreated')}:`,
      detail: `${from.format('dddd DD-MM-YYYY')}`,
      life: 5000,
    });
    store.commit('SET_CURRENT_RESERVATION', saveReservationResponse);
  },

  /**
   * removes reservation from databse
   */
  deleteReservation: async function (resId: string): Promise<void> {
    store.state.currentDay ? store.state.currentDay.date : null;
    store.commit('SET_IS_DELETING_RESERVATION', true);
    const deleteReservationResponse = await parkingReservationService.deleteReservation(resId);
    store.commit('SET_IS_DELETING_RESERVATION', false);
    if (deleteReservationResponse.isError) return;
    eventService.emit('ADD_TOAST', {
      severity: 'success',
      summary: `${(i18n as any).global.t('Global.bookingCancelled')}`,
      life: 5000,
    });
    router.push('/home');
  },

  deleteAdminReservation: async function (resId: string): Promise<void> {
    store.state.currentDay ? store.state.currentDay.date : null;
    store.commit('SET_IS_ADMIN_DELETING_RESERVATION', true);
    const deleteReservationResponse = await parkingReservationService.deleteAdminReservation(resId);
    store.commit('SET_IS_ADMIN_DELETING_RESERVATION', false);
    if (deleteReservationResponse.isError) return;
    eventService.emit('ADD_TOAST', {
      severity: 'success',
      summary: `${(i18n as any).global.t('Global.bookingCancelled')}`,
      life: 5000,
    });
    await actions.populateBookings();
    // window.location.reload();
    router.push('/admin');
  },

  changeMaintenanceMode: async function (maintenanceMode: boolean): Promise<boolean> {
    const changeResponse = await parkingReservationService.changeMaintenanceMode(maintenanceMode);

    if (changeResponse.isError) {
      eventService.emit('ADD_TOAST', {
        severity: 'warn',
        summary: 'Cannot set maintenance mode',
        life: 5000,
      });
      return false
    } else {
      const mode = Boolean(changeResponse.data).valueOf()
      store.commit('SET_IS_MAINTENANCE', maintenanceMode);
      return mode
    }
  },

  getMaintenanceMode: async function (): Promise<boolean> {
    const changeResponse = await parkingReservationService.isMaintenanceMode();

    if (changeResponse.isError) {
      eventService.emit('ADD_TOAST', {
        severity: 'warn',
        summary: 'Cannot get maintenance mode',
        life: 5000,
      });
      return false
    } else {
      const mode = Boolean(changeResponse.data).valueOf()
      store.commit('SET_IS_MAINTENANCE', mode);
      return mode
    }
  },
};

export default actions;
